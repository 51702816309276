body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
		'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
@media only screen and (resolution: 1dppx) {
	.ant-layout {
		height: 100vh;
	}
}
@media only screen and (resolution: 1.25dppx) {
	.ant-layout {
		height: 125vh;
	}
}
@media only screen and (resolution: 1.5dppx) {
	.ant-layout {
		height: 150vh;
	}
}
@media only screen and (resolution: 1.75dppx) {
	.ant-layout {
		height: 175vh;
	}
}
@media only screen and (resolution: 2dppx) {
	.ant-layout {
		height: 200vh;
	}
}
code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.clickable {
	cursor: pointer;
}

.bold {
	font-weight: 600;
	color: rgba(0, 0, 0, 0.85);
}

.ant-form-item-label label:not(.ant-form-item-required) {
	margin-left: 10.719px;
}

.full-screen .ant-modal,
.full-screen .ant-modal-content {
	height: 100vh;
	width: 100vw;
	margin: 0;
	top: 0;
}
.full-screen .ant-modal-body {
	height: calc(100vh - 110px);
}

.ant-form-item-explain-error > div {
	font-size: 12px;
}

input.ant-input-number-without-padding {
	padding: 5px 0px 5px 2px;
	font-size: 12px;
}

.register-upload-image .ant-upload-select-picture-card {
	width: 10vw;
	height: 10vw;
}
.register-upload-image .ant-upload-list-picture-card-container {
	width: 10vw;
	height: 10vw;
}

.register-upload-image .ant-upload-list-item {
	padding: unset;
	border: unset;
}
.ant-image-preview-operations {
	display: none;
}

.ant-image-preview-img {
	height: calc(100vh - 150px);
}

.ant-checkbox-wrapper.required .ant-checkbox-inner {
	border: none;
	outline: 1px solid #ff4d4f;
}

.ant-select-disabled span,
input:disabled,
.ant-input-disabled {
	color: rgba(0, 0, 0, 1) !important;
}

.red-field-selector {
	background-color: rgba(255, 168, 168, 1) !important;
}

.red-field-selector .ant-select-selector {
	background-color: rgba(255, 168, 168, 1) !important;
}

.white-field-selector {
	background-color: rgba(255, 255, 255, 1) !important;
}

.white-field-selector .ant-select-selector {
	background-color: rgba(255, 255, 255, 1) !important;
}

.yellow-field-selector {
	background-color: rgbargba(255, 250, 204, 1) !important;
}

.yellow-field-selector .ant-select-selector {
	background-color: rgba(255, 250, 204, 1) !important;
}

.ant-input-affix-wrapper.ant-input-password.password-input {
	padding: 0 5px 0 0;
}
.password-input input {
	padding: 10px !important;
}

.time-picker-popup-height .ant-picker-content {
	height: 120px !important;
}
td.ant-table-cell.elemAlignTop {
	vertical-align: top !important;
}

.tab-link {
	color: black;
	text-decoration: underline;
}

.tab-link:hover {
	color: rgb(24, 144, 255);
}

.ant-table-tbody > tr.ant-table-row:hover > td.ant-table-cell.ant-table-cell-row-hover {
	background: rgb(255, 255, 255);
}

.ant-table-tbody > tr.ant-table-row:hover > td.ant-table-cell.ant-table-cell-row-hover.highlight {
	background: rgba(24, 144, 255, 0.1);
}

.row-highlight .ant-table-cell {
	background: rgba(24, 144, 255, 0.1) !important;
	border-bottom: 1px solid rgba(255, 255, 255, 0) !important;
}
.pictogram:hover {
	color: rgba(24, 144, 255) !important;
}
.pictogram:hover line {
	stroke: rgba(24, 144, 255) !important;
}
.pictogram:hover path {
	fill: rgba(24, 144, 255) !important;
}
.tab-link-pictogram {
	color: white !important;
	text-decoration: underline white !important;
}
.tab-link-pictogram:hover {
	color: rgb(24, 144, 255) !important;
	text-decoration: underline rgb(24, 144, 255) !important;
}
.tabsWrapper .ant-tabs-tab {
	color: rgba(0, 0, 0, 1) !important;
	font-weight: 300 !important;
}

.tabsWrapper .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: rgba(0, 0, 0, 0.85) !important;
	border-bottom: 1px solid rgba(255, 255, 255, 0) !important;
	font-weight: 300 !important;
}

.tabsWrapper .ant-card-bordered {
	border-top: 0px !important;
}
