.table-tree {
	margin-right: 5px;
	display: inline-block;
}

.table-tree svg {
	color: #000;
	transition: all 0.1s linear;
}

.table-tree__open svg {
	transform: rotate(-90deg);
}

.empty-span {
	padding-left: 20px;
	display: inline-block;
}
