.ant-image-preview-body .ant-image-preview-switch-right,
.ant-image-preview-body .ant-image-preview-switch-left {
	display: none;
}
.custom-ant-image-preview-operations-top {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-size: 28px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	font-feature-settings: 'tnum', 'tnum';
	position: absolute;
	top: auto;
	top: 0;
	right: 0;
	z-index: 1;
	display: flex;
	height: 50px;
	align-items: center;
	justify-content: center;
	width: 100%;
	color: rgba(255, 255, 255, 0.85);
	list-style: none;
	background: #001529;
	pointer-events: auto;
	transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.custom-ant-image-preview-operations-bottom {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	font-feature-settings: 'tnum', 'tnum';
	position: absolute;
	top: auto;
	bottom: 0;
	right: 0;
	z-index: 1;
	display: flex;
	height: 50px;
	align-items: center;
	justify-content: center;
	width: 100%;
	color: rgba(255, 255, 255, 0.85);
	list-style: none;
	background: #001529;
	pointer-events: auto;
	transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.custom-ant-image-preview-operations-middle {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	font-feature-settings: 'tnum', 'tnum';
	position: absolute;
	top: auto;
	bottom: 50%;
	right: 0;
	z-index: 1;
	display: flex;
	height: 100px;
	align-items: center;
	justify-content: center;
	width: 100%;
	color: rgba(255, 255, 255, 0.85);
	list-style: none;
	background: transparent;
	pointer-events: auto;
	transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.custom-ant-image-preview-operations-operation {
	margin-left: 12px;
	padding: 12px;
	cursor: pointer;
	transform: scale(3);
}

.custom-ant-image-preview-switch-right {
	position: absolute;
	top: 50%;
	right: 10px;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 44px;
	height: 44px;
	margin-top: -22px;
	color: rgba(255, 255, 255, 0.85);
	background: rgba(0, 0, 0, 0.1);
	border-radius: 50%;
	cursor: pointer;
	pointer-events: auto;
}

.custom-ant-image-preview-switch-left {
	position: absolute;
	top: 50%;
	left: 10px;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 44px;
	height: 44px;
	margin-top: -22px;
	color: rgba(255, 255, 255, 0.85);
	background: rgba(0, 0, 0, 0.1);
	border-radius: 50%;
	cursor: pointer;
	pointer-events: auto;
}
