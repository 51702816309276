.settings-icon {
	width: 48px;
	height: 48px;
	font-size: 20px;
	background-color: #3b90f7;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	top: 8px;
	right: 350px;
	z-index: 0;
	cursor: pointer;
	text-align: center;
	color: #fafafa;
	box-shadow: 2px 2px 2px #393e44;
}

.settings-icon svg {
	position: relative;
	left: 0;
}

.settings-icon,
.settings-icon svg {
	transition: all 0.2s ease-in-out;
}

.settings-icon:hover {
	width: 56px;
}

.settings-icon:hover svg {
	left: -4px;
}

.layout-icon {
	position: relative;
	width: 44px;
	height: 36px;
	margin-right: 16px;
	overflow: hidden;
	background-color: #f0f2f5;
	border-radius: 4px;
	box-shadow: 0 1px 2.5px 0 #0000002e;
	cursor: pointer;
}

.layout-icon.left::before {
	content: '';
	background-color: #001529;
	z-index: 1;
	height: 100%;
	width: 33%;
	position: absolute;
	top: 0;
	left: 0;
}

.layout-icon.top::before {
	content: '';
	background-color: #001529;
	z-index: 1;
	height: 25%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
}
